export function useNotification() {
    const notification = useToast()

    function error(title: string, description: string, options?: Partial<Omit<ReturnType<typeof notification['add']>, 'title' | 'description'>>) {
        notification.add({
            title,
            description,
            color: 'red',
            timeout: 60 * 60 * 1000,
            ...(options || {}),
        })
    }
    function warning(title: string, description: string, options?: any) {
        notification.add({
            title,
            description,
            color: 'yellow',
            ...(options || {}),
        })
    }

    function success(title: string, description: string, options?: any) {
        notification.add({
            title,
            description,
            color: 'positive',
            ...(options || {}),
        })
    }

    return {
        error,
        warning,
        success,
    }
}
